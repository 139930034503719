<template>
  <b-container class="dashboard registration-is-not-completed bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col cols="12" md="12" lg="8" style="margin:auto;text-align:center">

        <b-img :src="require('@/assets/img/error.png')"></b-img>
        <h1>{{ $t('common.registration_is_not_completed.title') }}</h1>
        <i18n :path="'common.registration_is_not_completed.description'" tag="p" class="lead">
          <span slot="delay" class="delay-time">{{ redirectDelay }}</span>
        </i18n>

        <p class="lead">{{ $t('common.registration_is_not_completed.also', {delay: redirectDelay}) }}</p>
        <b-button
          variant="primary"
          class="mr-2 mb-2"
          @click="goToRegistration"
        >
          {{$t('common.registration_is_not_completed.btn_registration')}}
        </b-button>
        <b-button
          variant="primary"
          class="mb-2"
          @click="goToLogin"
        >
          {{$t('common.registration_is_not_completed.btn_login')}}
        </b-button>
        <br><small style="color:#f7f6f6">{{ env.VUE_APP_ID_STRING }}</small>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'RegistrationIsNotCompleted',
  data () {
    return {
      env: process.env,
      redirectDelay: 15,
      registrationLink: process.env.VUE_APP_ROOT_API.substr(0, process.env.VUE_APP_ROOT_API.length - 6) + 'register/step1'
    }
  },
  mounted () {
    const i = setInterval(() => {
      this.redirectDelay--
      if (this.redirectDelay <= 0) {
        clearInterval(i)
        window.location.href = this.registrationLink
      }
    }, 1000)
  },
  methods: {
    goToRegistration () {
      window.location.href = this.registrationLink
    },
    goToLogin () {
      console.log(window.location)
      window.location.href = window.location.origin + '/login'
    }
  }
}
</script>

<style lang="scss">
.registration-is-not-completed {
  .delay-time {
    font-weight: 900;
    color: #545b7a;
  }
}
</style>
